import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

export const ArrowLink = ({
  children,
  style,
  to,
  arrowLeft,
  size,
  largeFont,
  target,
  rel,
  ariaLabel,
  tabIndex,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  to: string;
  size?: number;
  largeFont?: boolean;
  arrowLeft?: boolean;
  target?: string;
  rel?: string;
  ariaLabel?: string;
  tabIndex?: number;
}): JSX.Element => {
  const fontSize = largeFont ? "1.5rem" : "1rem";

  return (
    <ArrowLinkStyled
      style={{
        ...style,
        fontSize,
      }}
      to={to}
      target={target}
      rel={rel}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      $arrowLeft={arrowLeft}
      $hasChildren={children ? true : false}
    >
      {!arrowLeft && <>{children}</>}
      <svg
        width={`${size || 3}rem`}
        height={`${size || 3}rem`}
        viewBox="0 0 219.151 219.151"
      >
        <g>
          <path
            className={"circle"}
            d=" M109.576,15c52.148,0,94.573,42.426,94.574,94.575
		c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z"
          />
          <path
            className={"arrow"}
            d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
		c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
		c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
		c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"
          />
        </g>
      </svg>
      {arrowLeft && <>{children}</>}
    </ArrowLinkStyled>
  );
};

const ArrowLinkStyled = styled(Link)<{
  $arrowLeft?: boolean;
  $hasChildren?: boolean;
}>`
  width: fit-content;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colours.foregroundPrimary};
  margin: ${({ $arrowLeft }) =>
    `0 ${$arrowLeft ? 0 : -10}px 0 ${$arrowLeft ? -10 : 0}px`};
  svg path {
    &.arrow {
      fill: ${(props) => props.theme.colours.foregroundPrimary};
      transform-origin: center;
      transform: ${({ $arrowLeft }) =>
        `${$arrowLeft ? "none" : "rotate(180deg)"}`};
    }
    &.circle {
      transform-origin: center;
      // transform: ${({ $arrowLeft }) =>
        `rotate(${$arrowLeft ? -90 : 90}deg)`};
      transform: rotate(90deg)
        ${({ $arrowLeft }) => `scaleY(${$arrowLeft ? -1 : 1})`}; // scaleY -1 flips the circle so it animates under, behind and then over the top of the arrow, a mirrored effect when either left or right arrow
      fill: transparent;
      stroke-width: 12px;
      stroke: ${(props) => props.theme.colours.midBlue};
      stroke-dasharray: 595;
      stroke-dashoffset: 595;
    }
  }
  &:hover,
  &:focus-visible {
    transition: all 0.3s ease;
    color: ${(props) => props.theme.colours.midBlue};
    svg {
      transition: all 0.3s ease;
      transform: ${({ $hasChildren, $arrowLeft }) =>
        $hasChildren ? `translateX(${$arrowLeft ? -5 : 5}px)` : "none"};
    }
    svg path.arrow {
      transition: all 0.3s ease;
      fill: ${(props) => props.theme.colours.midBlue};
    }
    svg path.circle {
      animation: animate-circle 0.3s linear forwards;
    }
    @keyframes animate-circle {
      0% {
        stroke-dashoffset: 595;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
  }
`;
