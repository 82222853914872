import styled from "styled-components";

export const SectionContainer = styled.section`
  overflow: hidden; /* Fixes unwanted scrollX caused by aos */
  padding: 2px; /* This allows for the tab focus to show */
`;
export const SectionRow = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4rem;
  align-items: center;
  div {
    max-width: ${(props) => props.theme.screenSize.s}px;
  }
  /*** positions ImageContainer to right of text in SectionRow ***/
  &:nth-child(odd) div:first-of-type {
    order: 2;
  }
  &:not(:last-of-type) {
    margin: 0 auto 6em auto;
  }
  &:last-of-type {
    margin: 0 auto;
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    display: block;
    div {
      max-width: none;
    }
  }
  p {
    margin: 2rem 0;
  }
  ol,
  ul {
    font-weight: bolder;
    li {
      margin-bottom: 1rem;
    }
  }
`;
export const SingleSectionRow = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4rem;
  align-items: center;
  div {
    max-width: ${(props) => props.theme.screenSize.s}px;
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    display: block;
    min-height: 60vh;
    margin: 4em auto;
    div {
      max-width: none;
    }
  }
  p {
    margin: 2rem 0;
  }
  ul {
    padding: 0;
  }
  li {
    list-style-type: none;
    font-weight: bolder;
    margin-bottom: 1rem;
  }
`;
export const SectionRowImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    margin-top: 4rem;
  }
`;
export const SectionRowImageStyled = styled.img`
  max-width: 100%;
  max-height: ${(props) => props.theme.screenSize.xs}px;
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    max-width: 80%;
  }
`;
